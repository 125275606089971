import * as React from "react";

const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="m9.5 21 8-8v-2l-8-8L8 4.5l7.5 7.5L8 19.5 9.5 21Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowRightIcon;

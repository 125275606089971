import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useRef, useState } from "react";
import { t } from "ttag";
import { Hash } from "viem";
import { useChains, useConfig } from "wagmi";
import { waitForTransactionReceipt } from "wagmi/actions";

import { TxResult } from "@/types/common";
import { is0X } from "@/util/check";
import { withExactMessage } from "@/util/error";

/** @deprecated should wrap component in suspense boundary */
export const useTxInfo = () => {
  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();

  const txn = useMemo(() => {
    const names = searchParams.getAll("functionName");
    const hashes = searchParams.getAll("hash");

    return names
      .map<TxResult>((functionName, i) => {
        const _hash = hashes[i];
        const hash = (is0X(_hash) ? _hash : undefined)!;
        return { functionName, hash };
      })
      .filter(({ hash }) => !!hash);
  }, [searchParams]);

  const saveTx = useCallback(
    (tx: TxResult) => {
      const nextUrl = `${pathname}?${searchParams.toString()}&functionName=${
        tx.functionName
      }&hash=${tx.hash}`;
      replace(nextUrl);
    },
    [pathname, replace, searchParams],
  );

  return { txn, saveTx };
};

export const useHandleTx = ({
  confirmations,
  reset,
  submit: _submit,
  onError,
  onHash,
  onSuccess,
}: {
  confirmations?: number;
  reset?: () => void;
  submit?: () => undefined | Promise<`0x${string}`>;
  onError?: (err: Error) => void;
  onHash?: (hash: `0x${string}`) => void;
  onSuccess?: (transactionHash: Hash) => void;
}) => {
  const [pending, setPending] = useState(false);
  const cancelRef = useRef<() => void>();
  const config = useConfig();
  const submit = useCallback(() => {
    const result = _submit?.();

    const cancel = new Promise<void>((resolve) => {
      cancelRef.current = resolve;
    });
    if (result) {
      setPending(true);
      Promise.race([result, cancel])
        .then(async (res) => {
          if (res) {
            onHash?.(res);
            try {
              const receipt = await waitForTransactionReceipt(config, {
                confirmations,
                hash: res,
              });
              return receipt.transactionHash;
              // On mainnet network the first ~10 minutes returns an error that transaction is not found, although it can be found in etherscan
            } catch (error) {
              return res;
            }
          } else {
            reset?.();
            throw withExactMessage(
              new Error(t`You canceled the action.`),
              `/images/cancel.svg`,
            );
          }
        })
        .then((transactionHash) => {
          if (!transactionHash) {
            setPending(false);
          } else {
            onSuccess?.(transactionHash);
          }
        })
        .catch((err) => {
          setPending(false);
          onError?.(err);
        });
    }
  }, [_submit, onHash, config, confirmations, reset, onSuccess, onError]);

  return { cancelRef, pending, setPending, submit };
};

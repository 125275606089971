import React from "react";

interface Props {
  className?: string;
}
export const FlashIcon: React.FC = ({ className }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6667 10L13 14H10.3333L9 22L19 10H13.6667Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6666 10L13 14H10.3333L11 10H13.6666Z"
      fill="black"
    />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3333 14L11 10H13.6667L15 2L5 14H10.3333Z"
      fill="black"
    />
  </svg>
);

import styled from "styled-components";
import { css } from "styled-components";

export const earnBarStyles = css`
  display: flex;
  margin-right: 16px;
  & > div {
    margin-right: -16px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--fill-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
  .coin img {
    width: 48px;
    height: 48px;
  }
`;

export const Card = styled.div`
  background: var(--fill-elevated);
  box-shadow: 0px 4px 8px rgba(51, 20, 0, 0.04),
    0px 8px 24px rgba(51, 20, 0, 0.08);
  border-radius: 32px;
  padding: 20px 24px 24px;
  color: var(--text-primary);
  height: fit-content;

  &.card__earn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24px;
    padding-bottom: 18px;
    height: fit-content;
  }

  .card {
    &__title {
      font-variation-settings: "wght" 700;
      letter-spacing: 0.02em;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
      color: var(--text-primary);
    }
    &__info {
      letter-spacing: 0.01em;
      color: var(--text-secondary);
    }
    &__position-percents {
      letter-spacing: 0.01em;
      color: #40bf00;
    }
    &__traderow {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }
    &__earnbar {
      ${earnBarStyles}
    }
    &__lendinfo {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__lendtime {
      letter-spacing: 0.03em;
      color: #72706e;
      font-variation-settings: "wght" 700;
    }
    &__titleflex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: static !important;
    }
  }

  &.earn__placeholder {
    box-shadow: 0 0 5px 10px var(--fill-elevated);
    display: flex;
    isolation: isolate;
    .bar,
    .label {
      position: relative;
      z-index: -1;
    }
  }

  &.earn__redesign {
    padding: 32px 40px 32px 32px;
    display: flex;
    gap: 24px;
    align-items: center;
    cursor: pointer;
    * {
      flex-shrink: 0;
      white-space: nowrap;
    }
    @media (max-width: 1439px) {
      padding: var(--spacing-space-24, 24px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "title earnings"
        "bar bar"
        "apr apr";
      gap: 16px 0;
      .earn__earnings {
        grid-area: earnings;
        margin-left: 0;
        min-width: 0;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .bar {
        grid-area: bar;
      }
      .label {
        grid-area: apr;
      }
    }
  }

  .earn {
    &__title {
      display: flex;
      gap: 12px;
      align-items: center;
      white-space: nowrap;
      flex-shrink: 0;
      grid-area: title;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: 1024px) {
        grid-area: unset;
        width: 194px;
      }
    }
    &__earnings {
      min-width: 142px;
      margin-left: 24px;
    }
  }

  .earn__label {
    display: flex;
  }

  @media (min-width: 1440px) {
    .earn__label {
      min-width: 178px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const formatUsd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
}).format;

export const formatPercent = (
  value: number | bigint,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options,
  }).format(value);

export const numberFormatter = new Intl.NumberFormat("en-US").format;

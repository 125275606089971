import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { useAccount } from "wagmi";

import { serverRedirect } from "@/actions/redirect";
import { useDexPartner } from "@/contexts/partner";

export const useCheckAccountConnection = () => {
  const router = useRouter();
  const account = useAccount();
  const { chainPath } = useDexPartner();

  useEffect(() => {
    if (!account.isConnected) {
      // The server redirect is used because the client redirect is not working correctly
      serverRedirect(chainPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.isConnected, router]);
};

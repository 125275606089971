import { PoolInfo } from "@/contracts/react/hooks";
import { normalizePtToken } from "@/util/common";
import { AvailableTokens } from "../common/token/token-icons";

export const fullFarmDateOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
} as const;

export const shortFarmDateOptions = {
  day: "numeric",
  month: "short",
} as const;

type FarmInToken = {
  address?: `0x${string}`;
  symbol: AvailableTokens;
  name: string;
  shouldBeSwapped: boolean;
  needApprove: boolean;
  decimals: number;
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const ETH: FarmInToken = {
  name: "ETH",
  symbol: "ETH",
  shouldBeSwapped: true,
  needApprove: false,
  decimals: 18,
};

const ARBITRUM_WETH: FarmInToken = {
  address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
  name: "WETH",
  symbol: "WETH",
  shouldBeSwapped: true,
  needApprove: true,
  decimals: 18,
};
const ARBITRUM_PT_weETH_27JUN2024: FarmInToken = {
  address: "0x1c27ad8a19ba026adabd615f6bc77158130cfbe4",
  name: "PT weETH",
  symbol: "PT-weETH-27JUN2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const ARBITRUM_PT_rsETH_26SEP2024: FarmInToken = {
  address: "0x30c98c0139B62290E26aC2a2158AC341Dcaf1333",
  name: "PT rsETH",
  symbol: "PT-rsETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const ARBITRUM_weETH: FarmInToken = {
  address: "0x35751007a407ca6FEFfE80b3cB397736D2cf4dbe",
  name: "weETH",
  symbol: "weETH",
  shouldBeSwapped: true,
  needApprove: true,
  decimals: 18,
};

const ARBITRUM_PT_USDe_29AUG2024: FarmInToken = {
  address: "0xad853EB4fB3Fe4a66CdFCD7b75922a0494955292",
  name: "PT Ethena USDe",
  symbol: "PT-USDe-29AUG2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

// const ARBITRUM_USDe: FarmInToken = {
//   address: "0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34",
//   name: "USDe",
//   shouldBeSwapped: true,
//   needApprove: true,
//   decimals: 18,
// };

const ARBITRUM_USDc: FarmInToken = {
  address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
  name: "USDc",
  symbol: "USDC",
  shouldBeSwapped: true,
  needApprove: true,
  decimals: 6,
};

const ARBITRUM_PT_ezETH_26SEP2024: FarmInToken = {
  address: "0x2CCFce9bE49465CC6f947b5F6aC9383673733Da9",
  name: "PT Renzo ezETH",
  symbol: "PT-ezETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

// const ARBITRUM_ezETH: FarmInToken = {
//   address: "0x2416092f143378750bb29b79eD961ab195CcEea5",
//   name: "Renzo Restaked ETH",
//   shouldBeSwapped: true,
//   needApprove: true,
//   decimals: 18,
// };

const ARBITRUM_PT_weETH_26SEP2024: FarmInToken = {
  address: "0xb8b0a120F6A68Dd06209619F62429fB1a8e92feC",
  name: "PT weETH",
  symbol: "PT-weETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const ARBITRUM_KNOX: FarmInToken = {
  address: "0x0BBF664D46becc28593368c97236FAa0fb397595",
  name: "KNOX Dollar",
  symbol: "KNOX",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

// const ARBITRUM_eUSD: FarmInToken = {
//   address: "0x12275DCB9048680c4Be40942eA4D92c74C63b844",
//   name: "eUSD",
//   shouldBeSwapped: true,
//   needApprove: true,
//   decimals: 18,
// };

const ARBITRUM_ETHPlus: FarmInToken = {
  address: "0x18C14C2D707b2212e17d1579789Fc06010cfca23",
  name: "ETHPlus",
  symbol: "ETH+",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_WETH: FarmInToken = {
  address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  name: "WETH",
  symbol: "WETH",
  shouldBeSwapped: true,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PT_rsETH_26SEP2024: FarmInToken = {
  address: "0x7bAf258049cc8B9A78097723dc19a8b103D4098F",
  name: "PT rsETH",
  symbol: "PT-rsETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

// const MAINNET_weETH: FarmInToken = {
//   address: "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
//   name: "weETH",
//   iconSrc: "/images/ptweth-colored.png",
//   shouldBeSwapped: true,
//   needApprove: true,
//   decimals: 18,
// };

const MAINNET_USDt: FarmInToken = {
  address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  name: "USDT",
  symbol: "USDT",
  shouldBeSwapped: true,
  needApprove: true,
  decimals: 6,
};

const MAINNET_PTweETH_27jun2024: FarmInToken = {
  address: "0xc69Ad9baB1dEE23F4605a82b3354F8E40d1E5966",
  name: "PT weETH",
  symbol: "PT-weETH-27JUN2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PTweETH_26dec2024: FarmInToken = {
  address: "0x6ee2b5e19ecba773a352e5b21415dc419a700d1d",
  name: "PT weETH",
  symbol: "PT-weETH-26DEC2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PT_UniETH_26SEP2024: FarmInToken = {
  address: "0x15fa86404bfba8b46684552f792558128bfb6418",
  name: "PT UniETH",
  symbol: "PT-uniETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PT_amphrETH_26SEP2024: FarmInToken = {
  address: "0x7fB0D250D4768E8590cB3c459549C7e33481511E",
  name: "PT amphrETH",
  symbol: "PT-amphrETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PT_pzETH_26SEP2024: FarmInToken = {
  address: "0xdBD8a24E780E7661c5c9f66310A3D4Ad309cD763",
  name: "PT pzETH",
  symbol: "PT-pzETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const MAINNET_PT_USDe_25JUL2024: FarmInToken = {
  address: "0xa0021EF8970104c2d008F38D92f115ad56a9B8e1",
  name: "USDe",
  symbol: "PT-USDe-25JUL2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};
const MAINNET_PT_weETH_26SEP2024: FarmInToken = {
  address: "0x1c085195437738d73d75DC64bC5A3E098b7f93b1",
  name: "PT ether.fi weETH",
  symbol: "PT-weETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};
const MAINNET_PT_ezETH_26SEP2024: FarmInToken = {
  address: "0x248241244356D18f63d0c16082996839ecB0C7bF",
  name: "PT Renzo ezETH",
  symbol: "PT-ezETH-26SEP2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};
const MAINNET_PT_ezETH_26DEC2024: FarmInToken = {
  address: "0xf7906F274c174A52d444175729E3fa98f9bde285",
  name: "PT Renzo ezETH",
  symbol: "PT-ezETH-26DEC2024",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const BLAST_USDe: FarmInToken = {
  address: "0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34",
  name: "USDe",
  symbol: "USDe",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

const BLAST_sUSDe: FarmInToken = {
  address: "0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2",
  name: "sUSDe",
  symbol: "sUSDe",
  shouldBeSwapped: false,
  needApprove: true,
  decimals: 18,
};

export const getTokenName = (poolInfo: PoolInfo | undefined): string => {
  const name = [
    ETH,
    ARBITRUM_WETH,
    ARBITRUM_PT_weETH_27JUN2024,
    ARBITRUM_weETH,
    ARBITRUM_PT_USDe_29AUG2024,
    MAINNET_PT_ezETH_26DEC2024,
    MAINNET_PT_ezETH_26SEP2024,
    MAINNET_PT_weETH_26SEP2024,
    MAINNET_PT_USDe_25JUL2024,
    MAINNET_PTweETH_26dec2024,
    MAINNET_PTweETH_27jun2024,
    ARBITRUM_PT_weETH_26SEP2024,
    ARBITRUM_PT_ezETH_26SEP2024,
  ].find(
    (token) =>
      token.address?.toLowerCase() ===
      poolInfo?.baseToken?.address?.toLowerCase(),
  )?.name;

  return (
    name ||
    normalizePtToken(poolInfo?.baseToken?.name) ||
    poolInfo?.baseToken?.name ||
    ""
  );
};

export const FARM_STATE: Record<
  number,
  Record<string, { tokens: FarmInToken[]; priceImpactToken?: FarmInToken }>
> = {
  1: {
    "0x86DC30f6dBbb742463CE77f88926eff979892b22": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_UniETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
    // pt-amphreth-26sep2024-amphreth
    "0xAc02A14F647029d2B2FF937032A2EDDfc6255A06": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_amphrETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
    // pt-pzeth-26sep2024-pzeth
    "0x51f95AAF94834b878eec18A812D840910370887f": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_pzETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0xE45FD040dCaF59276Be6AAC6A3C14e4707381AAC": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PTweETH_27jun2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0xa77C2275C1F403056c7F73B44df69E374C299dd7": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PTweETH_26dec2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0x4Cac44a1C50fea3F458f5F31529C0810AFcac497": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_weETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0xb34DfB03973e148ED800F369EdE84b92803116CA": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_ezETH_26DEC2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0x48f6E31CFe4da92C2470f8b797aa89a9460E9975": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_ezETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
    "0xa16C1F55129E4Af8288c005a27c8fE05591d24bE": {
      tokens: [ETH, MAINNET_WETH, MAINNET_PT_rsETH_26SEP2024],
      priceImpactToken: MAINNET_WETH,
    },
  },
  42161: {
    "0xcf848A30153506617B07207D2B3d054D4Fb72EBC": {
      tokens: [ETH, ARBITRUM_WETH, ARBITRUM_PT_weETH_27JUN2024],
      priceImpactToken: ARBITRUM_WETH,
    },
    "0x79C37E226834Dc6BfB04Ba0b60f823515c32f50D": {
      tokens: [ARBITRUM_USDc, ARBITRUM_PT_USDe_29AUG2024],
      priceImpactToken: ARBITRUM_USDc,
    },
    //
    "0x6128F06bF224F8407d5cBD7476D2BE0848aA05aF": {
      tokens: [ETH, ARBITRUM_WETH, ARBITRUM_PT_rsETH_26SEP2024],
      priceImpactToken: ARBITRUM_WETH,
    },
    "0x3ad4F88aF401bf5F4F2fE35718139cacC82410d7": {
      tokens: [ETH, ARBITRUM_WETH, ARBITRUM_PT_weETH_26SEP2024],
      priceImpactToken: ARBITRUM_WETH,
    },
    "0x44579419E975f4d59eaA0876f2EdCA7F2531821A": {
      tokens: [ETH, ARBITRUM_WETH, ARBITRUM_PT_ezETH_26SEP2024],
      priceImpactToken: ARBITRUM_WETH,
    },
    "0x3125e87D2630eb7DADD7F94e2fE05Bd21eE10C4f": {
      tokens: [ARBITRUM_KNOX],
    },
    "0xb1Af7B299ABBE4905Bbc80E614f64caCFEfBc96b": {
      tokens: [ARBITRUM_ETHPlus],
    },
  },
  81457: {
    "0xc91D6032d72796b9a0558Ab9ef45C100c9fDe1f8": {
      tokens: [BLAST_USDe],
    },
    "0x12afF531E86f5E9763c6C7D0994AdEfB1a1f844c": {
      tokens: [BLAST_sUSDe],
    },
  },
};

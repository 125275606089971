import { PositionType } from "@/contracts/transform/position";

export const checkIsWatch = (type?: PositionType) =>
  type === PositionType.Long || type === PositionType.Short;

export const checkIsLend = (type?: PositionType) => type === PositionType.Lend;

export const getWatchLink = ({
  address,
  chainName,
}: {
  address?: `0x${string}`;
  chainName: string;
}) => `/${chainName}/watch-position/${address}`;

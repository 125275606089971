const HOURS_IN_DAY = 24;

const MS_IN_SECOND = 1000;
const MS_IN_MIN = MS_IN_SECOND * 60;
const MS_IN_HOUR = MS_IN_MIN * 60;
const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY;

function getCurrentTimeUTC(date: Date) {
  return date.getTime() + date.getTimezoneOffset() * MS_IN_MIN;
}

export const getDayDifference = (
  startDate: string | Date,
  finishDate: string | Date,
) => {
  const finishDateInfo = new Date(finishDate);
  const startDateInfo = startDate ? new Date(startDate) : new Date();

  const dateDifferenceInMs =
    getCurrentTimeUTC(finishDateInfo) - getCurrentTimeUTC(startDateInfo);

  const days =
    dateDifferenceInMs > 0 ? Math.floor(dateDifferenceInMs / MS_IN_DAY) : 0;

  return days;
};

export const daysTillMaturity = (maturityDate?: string) => {
  if (!maturityDate) return { days: null, isHarvest: false };
  const startDateInfo = new Date();
  const maturityDateInfo = new Date(maturityDate);
  const dateDifferenceInMs =
    getCurrentTimeUTC(maturityDateInfo) - getCurrentTimeUTC(startDateInfo);

  const isHarvest = dateDifferenceInMs <= 0;
  const days = isHarvest ? 0 : Math.floor(dateDifferenceInMs / MS_IN_DAY);
  return { days, isHarvest };
};

export const getLiquidationDate = (date: string) => {
  const actualDate = new Date(date);

  const liquidationDateInMs = actualDate.getTime() + MS_IN_DAY * 14;

  const liquidationDate = new Date(liquidationDateInMs);

  return liquidationDate;
};

import { Tabs as TabsUnstyled } from "@marginly/ui/components/tabs";
import { TabItem as TabItemUnstyled } from "@marginly/ui/components/tabs";
import styled from "styled-components";

import { TokenImage as TokenImageUnstyled } from "@/components/common/token";
import { FlashIcon as FlashIconUnstyled } from "@/icons/flash-icon";

export const TradeLink = styled.div`
  display: flex;
  path {
    fill: var(--icon-secondary);
  }
`;

export const InputWrapper = styled.div`
  .input__span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  input {
    min-width: auto;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &.pendle {
    margin-right: 16px;
  }
  .powered {
    width: 91px;
    height: 91px;
    position: absolute;
  }
`;

export const TokenImage = styled(TokenImageUnstyled)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

export const BonusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .bonus {
    height: 24px;
  }
`;

export const BadgeRow = styled.div`
  margin-left: 8px;
  & > * {
    margin-left: -8px;
  }
`;

export const FlashIcon = styled(FlashIconUnstyled)<{ $isActive?: boolean }>`
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.3)};
  path {
    fill: ${({ $isActive }) =>
      $isActive ? "var(--icon-primary)" : "var(-icon--tertiary)"};
  }
`;

export const RiskSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  gap: 8px;
`;

export const RiskSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  .risk-title {
    white-space: nowrap;
  }
`;

export const TooltipLink = styled.a`
  color: var(--text-invert-primary, #fff);
  cursor: pointer;
  text-decoration: underline;
`;

export const Tabs = styled(TabsUnstyled)`
  width: 344px;
  height: 64px;
`;

export const TabItem = styled(TabItemUnstyled)`
  && {
    height: 100%;
    flex: 1;
    color: var(--text-positive);
    white-space: nowrap;
  }
`;

export const RiskDescription = styled.div`
  margin-bottom: 4px;
`;

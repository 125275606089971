import Banner from "@marginly/ui/components/banner";
import Button from "@marginly/ui/components/button";
import { CellItem } from "@marginly/ui/components/cell";
import Thumbnail from "@marginly/ui/components/thumbnail";
import T from "@marginly/ui/components/typography";
import BigNumber from "bignumber.js";
import React, { useState } from "react";

import { useWatchPosition } from "@/app/[chain]/watch-position/[pool]/use-watch-position";
import { getTokenSymbol } from "@/app/util";
import Portal from "@/components/common/portal";
import SwitchNetworkOr from "@/components/common/switch-network-or";
import { TokenIconAdaptive } from "@/components/common/token/token-icon/styled";
import { useNetPosition } from "@/components/homepage/farm-position-card/use-net-position";
import { SparkScoreLabel } from "@/components/ui/spark-score-label";
import { Noop } from "@/components/util";
import {
  BonusesCard,
  ModalBody,
  ModalFarm,
  ModalLayout,
} from "@/external/components/modal.styled";
import { daysDisplay } from "@/util/days-display";
import { formatCoin } from "@/util/format-coin";
import { formatPercent } from "@/util/formatters";
import {
  daysTillMaturity,
  getLiquidationDate,
} from "@/util/get-day-difference";
import { getPercentsToLiquidation } from "@/util/get-percents-to-liquidation";
import { getWatchLink } from "@/util/position-helpers";

import Space from "../../common/space";
import { fullFarmDateOptions, shortFarmDateOptions } from "../constants";
import {
  DaysBar,
  DaysPercents,
  DetailsButton,
  FarmModalTitle,
  FarmRow,
  FarmRowSection,
  InfoTooltip,
  PositionDetails,
} from "../styled";
import { getFarmingAlertText } from "../util";
import { TokenImage } from "./styled";
import { TermWarningModal } from "./term-warning-modal";

export const FarmPositionModal = ({
  onClose,
  maturityDate,
  poolAddress,
  chainName,
  points,
  pointsPerDay,
  leverage,
}: {
  onClose?: () => void;
  maturityDate?: string;
  poolAddress: `0x${string}`;
  chainName: string;
  points?: number;
  pointsPerDay?: number;
  leverage: BigNumber | undefined;
}) => {
  const {
    featureAmount,
    actualAmount,
    userCollateralDeposit,
    farmed,
    openPositionDate,
  } = useNetPosition({
    leverage,
    maturityDate: maturityDate || "",
    poolAddress,
  });
  const { days, isHarvest } = daysTillMaturity(maturityDate);

  const liquidationDate = maturityDate
    ? getLiquidationDate(maturityDate)
    : undefined;
  const [isTemWarning, setIsTermWarning] = useState(false);

  const hasNoMaturity = maturityDate === undefined;
  const {
    handleCloseClick,
    pending,
    prePending,
    baseSymbol,
    quoteSymbol,
    basePrice,
    position,
    apr,
  } = useWatchPosition(poolAddress, chainName);

  const farmingBaseApy = position.farmingBaseApy;

  const handleClosePosition = () => {
    handleCloseClick();
  };

  const payApr = apr?.pay || 0;
  const renderBanner = () => {
    if (hasNoMaturity) {
      return null;
    }
    if (isHarvest) {
      return (
        <Banner accent md>
          <img
            src="/images/info-dark.svg"
            alt=""
            style={{ filter: "invert(var(--svg-invert))" }}
          />
          <T caption>
            Close the position before{" "}
            {new Date(liquidationDate || "").toLocaleDateString(
              "en-US",
              shortFarmDateOptions,
            )}{" "}
            to avoid liquidation.
          </T>
        </Banner>
      );
    }

    if (!liquidationDate) {
      return null;
    }

    return (
      <Banner accent md>
        <img
          src="/images/info-dark.svg"
          alt=""
          style={{ filter: "invert(var(--svg-invert))" }}
        />
        <T caption>{getFarmingAlertText(liquidationDate)}</T>
      </Banner>
    );
  };

  const renderButton = () => {
    if (hasNoMaturity) {
      return (
        <Button
          lg
          fullWidth
          className="btn--action"
          secondary
          style={{ color: `var(--text-negative, #E54796)` }}
          onClick={handleClosePosition}
        >
          Stop Farming
        </Button>
      );
    }
    if (isHarvest) {
      return (
        <Button
          lg
          fullWidth
          className="btn--action"
          onClick={handleClosePosition}
        >
          Harvest
        </Button>
      );
    }
    return (
      <Button
        lg
        className="btn--action"
        secondary
        fullWidth
        style={{ color: `var(--text-negative, #E54796)` }}
        onClick={() => setIsTermWarning(true)}
      >
        Stop Farming
      </Button>
    );
  };

  return (
    <Portal>
      <ModalFarm className="farm">
        <ModalLayout onClick={onClose} />
        <Button onClick={onClose} className="close">
          <img src="/images/close.svg" alt="" />
        </Button>

        <ModalBody>
          <div>
            <Button onClick={onClose} className="close close--mobile">
              <img src="/images/back-button.svg" alt="" />
            </Button>

            <FarmModalTitle>
              <div>
                <T headerL>
                  {hasNoMaturity
                    ? formatCoin(actualAmount)
                    : formatCoin(featureAmount)}
                  &nbsp;{getTokenSymbol(quoteSymbol)}
                </T>
                {hasNoMaturity ? (
                  <T secondary body>
                    Farmed
                  </T>
                ) : (
                  <T secondary body>
                    On{" "}
                    {new Date(maturityDate || "").toLocaleDateString(
                      "en-US",
                      fullFarmDateOptions,
                    )}
                  </T>
                )}
              </div>
              <TokenImage token={quoteSymbol} />
            </FarmModalTitle>
            <Space height={0} heightMobile={2} />

            <Space height={24} heightMobile={24} />

            {!isHarvest && days !== null ? (
              <div>
                <DaysBar>
                  <DaysPercents style={{ width: "20%" }} />
                </DaysBar>
                <Space height={10} heightMobile={10} />
                <T body>{daysDisplay(days)} left</T>

                <Space height={20} heightMobile={20} />
              </div>
            ) : (
              <></>
            )}

            <PositionDetails>
              <FarmRow>
                <T headerS>Position</T>
                <DetailsButton>
                  <a href={getWatchLink({ address: poolAddress, chainName })}>
                    <T action secondary>
                      Manage
                    </T>
                  </a>
                </DetailsButton>
              </FarmRow>
              <Space height={4} heightMobile={4} />
              <FarmRow>
                <FarmRowSection>
                  <Thumbnail md rectangle>
                    <img
                      src="/images/deposit.svg"
                      alt=""
                      style={{ filter: "invert(var(--svg-invert))" }}
                    />
                  </Thumbnail>
                  <CellItem reverse label={"Deposited"}>
                    <T body>
                      {formatCoin(userCollateralDeposit)}
                      &nbsp;{getTokenSymbol(baseSymbol)}
                    </T>
                  </CellItem>
                </FarmRowSection>
                {farmed > 0 && (
                  <InfoTooltip
                    content={
                      <>
                        {formatCoin(farmed)} {getTokenSymbol(quoteSymbol)}{" "}
                        earned since{" "}
                        {new Date(openPositionDate).toLocaleDateString(
                          "en-US",
                          shortFarmDateOptions,
                        )}
                      </>
                    }
                  />
                )}
              </FarmRow>
              <Space height={16} heightMobile={16} />

              <FarmRow>
                <FarmRowSection>
                  <Thumbnail md rectangle>
                    <img
                      src="/images/percent.svg"
                      alt=""
                      style={{ filter: "invert(var(--svg-invert))" }}
                    />
                  </Thumbnail>
                  <CellItem reverse label="APY">
                    <T body>
                      {farmingBaseApy && formatPercent(farmingBaseApy / 100)}
                    </T>
                  </CellItem>
                </FarmRowSection>
                <InfoTooltip
                  content={
                    <>
                      You earn{" "}
                      {formatPercent(((farmingBaseApy || 0) + payApr) / 100)} on{" "}
                      {getTokenSymbol(baseSymbol)} You pay{" "}
                      {formatPercent(payApr / 100)} on{" "}
                      {getTokenSymbol(quoteSymbol)}
                    </>
                  }
                />
              </FarmRow>
              <Space height={16} heightMobile={16} />

              <FarmRow>
                <FarmRowSection>
                  <Thumbnail md rectangle>
                    <TokenIconAdaptive token={baseSymbol} />
                  </Thumbnail>
                  <CellItem reverse label={`${baseSymbol} price`}>
                    <T body>
                      {formatCoin(basePrice)} {getTokenSymbol(quoteSymbol)}
                    </T>
                  </CellItem>
                </FarmRowSection>
                <CellItem
                  reverse
                  label={`Liquidation in ${formatPercent(
                    Math.abs(
                      getPercentsToLiquidation(
                        basePrice,
                        position?.liquidationPrice,
                      ) || 0,
                    ),
                  )}`}
                >
                  <T body>
                    {formatCoin(position?.liquidationPrice)}{" "}
                    {getTokenSymbol(quoteSymbol)}
                  </T>
                </CellItem>
              </FarmRow>
              <Space height={16} heightMobile={16} />
              {renderBanner()}
            </PositionDetails>

            <Space height={20} heightMobile={18} />

            {points && maturityDate !== undefined && (
              <BonusesCard>
                <CellItem label={`You earn ${pointsPerDay} Spark per day`}>
                  Bonuses
                </CellItem>
                <CellItem>
                  <SparkScoreLabel score={points} />
                </CellItem>
              </BonusesCard>
            )}

            <Space height={32} heightMobile={32} />
            <SwitchNetworkOr chainName={chainName}>
              {renderButton()}
            </SwitchNetworkOr>
          </div>
        </ModalBody>
      </ModalFarm>
      {isTemWarning ? (
        <TermWarningModal
          days={days}
          onClosePosition={handleClosePosition}
          onClose={() => {
            setIsTermWarning(false);
          }}
          simulationPending={prePending}
          pending={pending}
          symbol={quoteSymbol}
          featureAmount={featureAmount}
          actualAmount={actualAmount}
        />
      ) : (
        <Noop />
      )}
    </Portal>
  );
};

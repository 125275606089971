"use client";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { parseUnits } from "viem";
import { useSimulateContract, useWriteContract } from "wagmi";

import { NULL_ADDRESS } from "@/constants/common";
import { CallType } from "@/constants/enum";
import { GAS_CLOSE } from "@/constants/gas";
import { useRx } from "@/contexts/rx";
import { useSwapCallData } from "@/contracts/react/hooks";
import {
  positionEnumToStr,
  PositionType,
} from "@/contracts/transform/position";
import { getExecuteParams } from "@/contracts/tx/execute";
import { useDisplayError } from "@/hooks/error";
import { useFeatureFlags } from "@/hooks/feature";
import { useGas } from "@/hooks/gas";
import {
  usePoolCoeffs,
  usePoolData,
  usePoolParams,
  usePositionsInfo,
} from "@/hooks/position";
import { usePushSubject } from "@/hooks/rx";
import { useHandleTx } from "@/hooks/tx";
import { useCheckAccountConnection } from "@/hooks/use-check-account-connection";
import { useApply } from "@/hooks/util";
import { markWritable } from "@/util/core";
import { formatCoin } from "@/util/format-coin";
import {
  trackClosePositionError,
  trackClosePositionSuccess,
  trackDepositStart,
  trackIncreaseLeverageStart,
  trackWithdrawStart,
} from "@/util/tracking";

const CLOSE_POSITION_ARGS = markWritable([
  CallType.ClosePosition,
  BigInt(0),
  BigInt(0),
  false,
  NULL_ADDRESS,
] as const);

export const useWatchPosition = (pool?: `0x${string}`, chain?: string) => {
  const router = useRouter();
  const flags = useFeatureFlags(pool);
  const coeffs = usePoolCoeffs(pool);
  const poolRaw = usePoolData(pool);
  const { error$ } = useRx();

  useCheckAccountConnection();
  useEffect(() => {
    if (poolRaw.position?.type === PositionType.Uninitialized) {
      router.push(`/${chain!}/trade/${pool}`);
    }
  }, [poolRaw.position?.type, pool, router, chain]);

  const innerTopRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isAprInfoVisible, setIsAprInfoVisible] = useState(false);
  const params = useApply(poolRaw.getPositionParams, coeffs);

  const poolParams = usePoolParams(pool);
  const position = usePositionsInfo(pool, {
    leverage: Number(params?.leverage.toFixed(2)),
  });
  const apr = position?.apr;

  const poolInfo = useMemo(() => {
    const { base, quote, position } = poolRaw;
    const isLong = position?.type === PositionType.Long;

    const leveragePercentage = `${
      poolParams.maxLeverage
        ? params?.leverage
            ?.dp(0)
            .div(poolParams.maxLeverage)
            .times(100)
            .toString()
        : " - "
    }%`;

    const [debtSymbol, collateralSymbol] = isLong
      ? [quote?.symbol, base?.symbol]
      : [base?.symbol, quote?.symbol];

    const debt = params?.debt?.div(
      !isLong && params?.basePrice ? params.basePrice : 1,
    );

    return {
      quoteSymbol: quote?.symbol ?? "",
      baseSymbol: base?.symbol ?? "",

      basePrice: params?.basePrice,
      leveragePercentage,
      leverage: params?.leverage,
      positionType: positionEnumToStr(position?.type),
      baseAmount: formatCoin(params?.realBaseAmount),
      collateralSymbol,
      debtAmount: debt,
      debtSymbol,
    };
  }, [
    poolRaw,
    poolParams.maxLeverage,
    params?.leverage,
    params?.basePrice,
    params?.debt,
    params?.realBaseAmount,
  ]);

  const { gas, gasPrice, useError } = useGas(GAS_CLOSE);

  const swapCallData = useSwapCallData({
    poolAddress: pool,
    callType: "ClosePosition",
    exactAmount: parseUnits(
      (poolInfo.debtAmount ?? 0).toString(),
      poolRaw[poolRaw.position?.type === PositionType.Long ? "quote" : "base"]
        ?.decimals ?? 0,
    ).toString(),
    positionType:
      poolRaw.position?.type === PositionType.Long ? "Long" : "Short",
  });

  const closePositionParams = useSimulateContract({
    ...getExecuteParams(CLOSE_POSITION_ARGS, {
      basePrice: poolRaw.basePrice,
      baseDecimals: poolRaw.base?.decimals,
      quoteDecimals: poolRaw.quote?.decimals,
      // @ts-expect-error
      positionType: position.positionType?.toLowerCase(),
      swapCallData,
      version: flags?.flip ? "v15" : "v1",
    }),
    query: { enabled: Boolean(swapCallData && poolRaw.position?.type) },
    address: pool,
    gas,
    gasPrice,
    // @ts-ignore
    value: BigInt(0),
  });

  useError(closePositionParams.error);
  const { lastError } = useDisplayError(closePositionParams.refetch);
  const closePositionTx = useWriteContract();
  usePushSubject(error$, closePositionTx.error);

  const handleIncreaseLeverageClick = useCallback(() => {
    if (!pool) {
      return;
    }

    trackIncreaseLeverageStart();
    router.push(`/${chain!}/increase-leverage/${pool}`);
  }, [pool, router, chain]);

  const positionType =
    poolRaw.position?.type === PositionType.Long ? "base" : "quote";

  const handlePlusClick = useCallback(() => {
    const type =
      poolRaw.position?.type === PositionType.Long ? "base" : "quote";

    if (!pool) {
      return;
    }

    trackDepositStart("watch-position");
    router.push(`/${chain!}/deposit/${type}:${pool}`);
  }, [pool, router, poolRaw.position?.type, chain]);

  const handleMinusClick = useCallback(() => {
    const type =
      poolRaw.position?.type === PositionType.Long ? "base" : "quote";

    if (!pool) {
      return;
    }

    trackWithdrawStart("watch-position");
    router.push(`/${chain}/withdraw/${type}:${pool}`);
  }, [pool, router, poolRaw.position?.type, chain]);

  const handleRepayClick = useCallback(() => {
    // todo resolve why type !== positionType
    const type =
      poolRaw.position?.type === PositionType.Long ? "quote" : "base";

    if (!pool) {
      return;
    }

    trackDepositStart("watch-position");
    router.push(`/${chain!}/deposit/${type}:${pool}`);
  }, [pool, router, poolRaw.position?.type, chain]);

  const _submitTransaction = useMemo(() => {
    if (closePositionParams.error) {
      return lastError;
    }
    if (!closePositionParams.data) {
      return undefined;
    }
    return () =>
      closePositionTx.writeContractAsync(closePositionParams.data.request);
  }, [
    closePositionParams.data,
    closePositionParams.error,
    closePositionTx,
    lastError,
  ]);

  const {
    pending,
    submit: handleCloseClick,
    cancelRef,
  } = useHandleTx({
    reset: closePositionTx.reset,
    submit: _submitTransaction,
    onError: (e) => {
      console.error(e);
      trackClosePositionError(e.message);
      error$.next(e);
    },
    onSuccess: () => {
      trackClosePositionSuccess();
      router.push(
        `/${chain!}/close-position-success?debtAmount=${
          poolInfo.debtAmount
        }&debtSymbol=${
          poolInfo.debtSymbol
        }&poolAddress=${pool}&collateralAmount=${
          position?.collateralBalanceDelta ?? ""
        }&collateralSymbol=${poolInfo.collateralSymbol}&chain=${
          chain ?? ""
        }&token=${positionType}&userCollateralDeposit=${
          position.userCollateralDeposit
        }`,
      );
    },
  });

  return {
    ...poolInfo,
    base: poolRaw.base,
    quote: poolRaw.quote,
    flags,
    apr,
    cancelRef,
    handleCloseClick,
    handleMinusClick,
    handlePlusClick,
    handleRepayClick,
    handleIncreaseLeverageClick,
    isAprInfoVisible,
    prePending: closePositionParams.isLoading || closePositionTx.isPending,
    pending,
    position,
    setIsAprInfoVisible,
    innerTopRef,
    wrapperRef,
  };
};

import BigNumber from "bignumber.js";

import { getFarmingPositionParams } from "@/components/farm/util";
import { usePositionsInfo } from "@/hooks/position";

import { getDayDifference } from "../../../util/get-day-difference";

export const useNetPosition = ({
  poolAddress,
  maturityDate,
  leverage,
}: {
  poolAddress?: `0x${string}`;
  maturityDate: string;
  leverage: BigNumber | undefined;
}) => {
  const {
    openPrice = 0,
    openTimestamp = 0,
    currentBasePrice = 0,
    pnl = 0,
    userCollateralDeposit = 0,
    borrowCurrentQuoteApr = 0,
    farmingBaseApy = 0,
  } = usePositionsInfo(poolAddress, { leverage: leverage?.toNumber() });

  const initialAmount = openPrice * userCollateralDeposit;

  const openPositionDate = new Date(openTimestamp * 1000);

  const maturityInDays = getDayDifference(openPositionDate, maturityDate) || 1;

  const farmed = currentBasePrice * pnl;

  const actualAmount = initialAmount + farmed;

  const featureAmount =
    getFarmingPositionParams({
      basePrice: new BigNumber(openPrice),
      depositAmount: userCollateralDeposit.toString(),
      maturityInDays: maturityInDays,
      leverage: leverage?.toNumber(),
      payApr: borrowCurrentQuoteApr / 100,
    }).netPositionAtMaturity || 0;

  return {
    actualAmount,
    featureAmount,
    farmed,
    farmingBaseApy,
    userCollateralDeposit,
    openPositionDate,
  };
};

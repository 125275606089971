import BigNumber from "bignumber.js";
import { useEffect } from "react";

import { ONE, ZERO } from "@/constants/math";
import { isValidInputAmount } from "@/util/check";

const ANNUAL_PERCENTAGE_DIFF = 0.02; // 2% magic percentage

// =((1+(1-basePrice)/basePrice)^(365/maturityInDays)-1)
export const getImpliedApy = ({
  basePrice,
  maturityInDays,
}: {
  basePrice: BigNumber | undefined;
  maturityInDays: number;
}) =>
  basePrice
    ? Math.pow(
        ONE.minus(basePrice).div(basePrice).plus(1).toNumber(),
        365 / maturityInDays,
      ) - 1
    : undefined;

const getInterestApr = ({ impliedApy }: { impliedApy: number | undefined }) =>
  impliedApy ? impliedApy - ANNUAL_PERCENTAGE_DIFF : undefined;

export const getDebtAtMaturity = ({
  impliedApy,
  maturityInDays,
  debtAmount,
}: {
  impliedApy: number | undefined;
  maturityInDays: number;
  debtAmount: number;
}) =>
  impliedApy
    ? (1 + impliedApy - ANNUAL_PERCENTAGE_DIFF) ** (maturityInDays / 365) *
      debtAmount
    : undefined;

export const getDebtAtMaturityNew = ({
  impliedApy,
  maturityInDays,
  debtAmount,
}: {
  impliedApy: number | undefined;
  maturityInDays: number;
  debtAmount: number;
}) =>
  impliedApy
    ? debtAmount * (1 + impliedApy * (maturityInDays / 365))
    : undefined;

export const getNetPositionAtMaturity = ({
  collateral,
  debtAtMaturity,
}: {
  collateral: number;
  debtAtMaturity: number | undefined;
}) => (debtAtMaturity ? collateral - debtAtMaturity : undefined);

export const getAPY = ({
  netPositionAtMaturity,
  depositedBase,
  basePrice,
  maturityInDays,
}: {
  netPositionAtMaturity: number | undefined;
  depositedBase: number | undefined;
  basePrice: BigNumber | undefined;
  maturityInDays: number;
}) => {
  if (!netPositionAtMaturity || !depositedBase || !basePrice) {
    return undefined;
  }
  const netPosition = basePrice.times(depositedBase);

  return (
    (netPositionAtMaturity / netPosition.toNumber()) ** (365 / maturityInDays) -
    1
  );
};

export const getFarmingPositionParamsOld = ({
  basePrice,
  maturityInDays,
  depositAmount,
  leverage,
}: {
  basePrice: BigNumber | undefined;
  maturityInDays: number;
  depositAmount: string | undefined;
  leverage: number | undefined;
}) => {
  if (!basePrice || !leverage) {
    return {
      impliedApy: undefined,
      debtAtMaturity: undefined,
      netPositionAtMaturity: undefined,
      apy: undefined,
    };
  }
  const depositedBase =
    depositAmount && isValidInputAmount(depositAmount) ? +depositAmount : 1;
  const collateral = depositedBase * leverage;
  const debtAmount = depositedBase * (leverage - 1) * basePrice.toNumber();
  const impliedApy = getImpliedApy({ basePrice, maturityInDays });
  const interestApr = getInterestApr({ impliedApy });
  const debtAtMaturity = getDebtAtMaturity({
    impliedApy,
    maturityInDays,
    debtAmount,
  });
  const netPositionAtMaturity = getNetPositionAtMaturity({
    collateral,
    debtAtMaturity,
  });
  const apy = getAPY({
    netPositionAtMaturity,
    depositedBase,
    basePrice,
    maturityInDays,
  });

  return {
    apy,
    debtAtMaturity,
    impliedApy,
    interestApr,
    netPositionAtMaturity,
  };
};

export const getFarmingPositionParams = ({
  basePrice,
  maturityInDays,
  depositAmount,
  leverage,
  payApr,
}: {
  basePrice: BigNumber | undefined;
  maturityInDays: number | null;
  depositAmount: string | undefined;
  leverage: number | undefined;
  payApr: number | undefined;
}) => {
  if (!basePrice || !leverage || !depositAmount || !maturityInDays) {
    return {
      debtAtMaturity: undefined,
      netPositionAtMaturity: undefined,
      debt: ZERO,
      collateral: ZERO,
    };
  }
  const depositedBase = isValidInputAmount(depositAmount) ? +depositAmount : 0;
  const collateral = ZERO.plus(depositAmount || 0).times(leverage || 0);
  const debt = ZERO.plus(depositedBase ?? 0)
    .times((leverage || 0) - 1)
    .times(basePrice ?? 0);

  const debtAtMaturity = getDebtAtMaturityNew({
    impliedApy: payApr,
    maturityInDays,
    debtAmount: debt.toNumber(),
  });
  const netPositionAtMaturity = collateral.toNumber() - (debtAtMaturity || 0);

  return {
    debtAtMaturity,
    netPositionAtMaturity,
    debt,
    collateral,
  };
};

export const getFarmingAlertText = (liquidationDate: Date) => {
  const dateText = new Date(liquidationDate)
    .toLocaleDateString("en-US", {
      weekday: undefined,
      day: "numeric",
      month: "short",
      year: "2-digit",
    })
    .replace(", ", "′");

  return `Stop farming by ${dateText} to avoid liquidation. This deadline allows farmers to close positions without locking out lenders.`;
};

import styled from "styled-components";

import { Card } from "@/components/common/card";
import { InfoTooltip as InfoTooltipUnstyled } from "@/components/ui/info-tooltip";

export const FarmLogo = styled.img`
  position: absolute;
  width: 160px;
  height: 160px;
  object-fit: contain;
  right: -32px;
  bottom: -24px;
  z-index: -2;
  border-radius: 50%;
`;

export const MarginlyPoints = styled.div`
  border-radius: 16px;
  background: var(--Points, #c8ff26);
  display: flex;
  align-items: center;
  padding: 5px;
  font-variation-settings: "wght" 600;
  color: #000;
`;

export const BonusSection = styled.div`
  position: absolute;
  top: 32px;
  right: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  .bonus {
    height: 32px;
  }
`;

export const FarmCard = styled(Card)`
  padding: 30px 32px 32px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  isolation: isolate;
  flex-direction: column;
  display: flex;
  min-height: 256px;

  @media (min-width: 1024px) {
    min-height: 304px;
    .headerM {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.32px;
    }
  }
`;

export const FarmBottom = styled.div`
  margin-top: auto;
`;

export const FarmLabel = styled.div`
  border-radius: 8px;
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  backdrop-filter: blur(20px);
  height: 48px;

  display: flex;
  flex-direction: column;
  .overflow {
    padding: 10px 16px;
    width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-variation-settings: "wght" 700;
  }
`;

export const FarmModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  p + p {
    margin-top: 6px;
  }
`;

export const FarmDetails = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  padding: 12px 24px 24px;
  p + p {
    margin-top: 0;
    white-space: nowrap;
  }
  &.disabled {
    opacity: 0.24;
    pointer-events: none;
  }
`;

export const PositionDetails = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-elevated, #fff);
  box-shadow: 0px 4px 8px 0px rgba(51, 20, 0, 0.04),
    0px 8px 24px 0px rgba(51, 20, 0, 0.08);
  padding: 14px 24px 24px;
`;

export const FarmRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  & > div:nth-child(2) {
    text-align: right;
  }
  .info-row {
    display: flex;
    gap: 6px;
    align-items: center;
    white-space: nowrap;
  }
  .negative {
    color: var(--text-negative, #e54796);
  }
`;

export const FarmRowSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const InfoTooltip = styled(InfoTooltipUnstyled)`
  .tooltip-body {
    width: 110px;
  }
`;

export const FarmBonusRow = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  & > div:nth-child(2) {
    text-align: right;
  }
`;

export const DetailsButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  max-width: 48px;
  z-index: 9;
`;

export const DropdownLayer = styled.div`
  background: var(--background-overlay, rgba(245, 241, 240, 0.8));
  backdrop-filter: blur(12px);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DropdownBody = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-elevated, #fff);
  padding: var(--spacing-space-24, 24px) 0;
  box-shadow: 0px 8px 32px 0px rgba(51, 20, 0, 0.16);
  isolation: isolate;
  padding: 24px;
  width: 100%;
  z-index: 9999;
  border-radius: var(--rounding-radius-xxl, 32px)
    var(--rounding-radius-xxl, 32px) var(--rounding-radius-none, 0px)
    var(--rounding-radius-none, 0px);

  &.non-portal {
    display: none;
  }
  &::before {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    border-radius: 2px;
    background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
    width: 32px;
    height: 4px;
    display: block;
  }

  .header {
    text-align: center;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-moz-scrollbar {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    background-color: transparent;
    display: none !important;
  }
  &::-webkit-scrollbar-track-piece {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    background-color: transparent;
    display: none !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
    background: transparent;
    background-color: transparent;
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  @media (min-width: 1024px) {
    bottom: -8px;
    right: -6px;
    left: auto;
    top: auto;
    width: 280px;
    padding: var(--spacing-space-16, 16px);
    border-radius: var(--rounding-radius-xl, 24px);
    transform: translateY(100%);
    &::before {
      display: none;
    }
    &.body-portal {
      display: none;
    }
    &.non-portal {
      display: block;
    }
  }
  .secondary .swap-icon {
    filter: invert(var(--svg-invert)) !important;
  }
`;

export const AssetsRow = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  @media (min-width: 1024px) {
    gap: 16px;
  }
`;

export const AssetItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .arrow-right {
    margin-left: auto;
  }
`;

export const DaysBar = styled.div`
  position: relative;
  border-radius: var(--rounding-radius-xxs, 4px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  height: 8px;
`;

export const DaysPercents = styled.div`
  border-radius: var(--rounding-radius-xxs, 4px);
  background: var(--fill-accent-primary, #9fe);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
`;

export const DaysCard = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  backdrop-filter: blur(12px);
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  padding: 4px 8px 4px 4px;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const ModalTermBody = styled.div`
  position: absolute;
  box-shadow: 0px 8px 32px 0px rgba(51, 20, 0, 0.16);
  background: var(--background-elevated, #252222);
  border-radius: var(--rounding-radius-xxl, 32px);
  padding: var(--spacing-space-48, 48px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  left: 8px;
  bottom: 8px;
  right: 8px;
  height: auto;

  .headerM {
    font-size: 24px;
    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }
  .thumbnail img {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 1024px) {
    max-width: 520px;
    width: 520px;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    border-radius: var(--rounding-radius-xxxl, 48px);
    background: var(--background-elevated, #fff);
    box-shadow: 0px 8px 32px 0px rgba(51, 20, 0, 0.16);
    height: fit-content;
    padding: 48px;
    .thumbnail {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin-top: 32px;

      img {
        width: 48px;
        height: 48px;
      }
    }
  }
`;

export const FarmModalInfo = styled.div`
  text-align: center;
`;

export const FarmTitle = styled.div`
  @media (min-width: 1024px) {
    padding: 0 16px;
  }
`;

export const PointsBlock = styled.div`
  display: flex;
  align-items: center;
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--Points, #c8ff26);
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) 3px;

  height: 24px;
  overflow: hidden;
  .caption {
    font-style: italic;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;

export const BonusBlock = styled.div<{ background: string; color: string }>`
  display: flex;
  align-items: center;
  border-radius: var(--rounding-radius-m, 16px);
  background: ${(props) => props.background};
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) 3px;
  height: 24px;

  .caption {
    font-style: italic;
    color: ${(props) => props.color};
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;

export const LabelsWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (min-width: 1024px) {
    flex-direction: column;
  }
`;

export const SuperfieldWrapper = styled.div`
  @media (max-width: 768px) {
    .input {
      max-width: calc(100% - 135px);
    }
  }
`;

export const HighImpactWarning = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-negative-bg);
  padding: var(--spacing-space-12, 12px) var(--spacing-space-16, 16px);
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SectionGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) {
    gap: 24px;
  }
`;

export const Banner = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 82px 16px;
  img {
    height: 64px;
    width: 64px;
    margin-bottom: 32px;
  }
  .header {
    margin-bottom: 8px;
  }
  @media (min-width: 1024px) {
    img {
      height: 96px;
      width: 96px;
    }
  }
`;

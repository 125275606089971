import styled from "styled-components";

export const TooltipBody = styled.div`
  border-radius: var(--rounding-radius-s, 12px);
  background: var(--fill-primary, #000);
  padding: 10px var(--spacing-space-12, 12px);
  left: 50%;
  top: -10px;
  transform: translate(-50%, -100%);
  position: absolute;
  width: 240px;
  text-align: center;
  white-space: normal;
  display: none;
  &::before {
    content: " ";
    z-index: 1000;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background: var(--fill-primary, #000);
    width: 14px;
    height: 14px;
  }
  &::after {
    content: " ";
    bottom: 0;
    position: absolute;
    left: 50%;
    width: 60px;
    height: 20px;
    transform: translate(-50%, 100%);
  }
  .caption {
    color: var(--text-invert-primary, #fff);
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  img {
    opacity: 0.3;
    filter: invert(var(--svg-invert));
    cursor: pointer;
  }
  &:hover ${TooltipBody} {
    display: block;
  }
`;

import T from "@marginly/ui/components/typography";
import React from "react";

import * as S from "./styled";

interface Props {
  content: React.ReactNode;
  className?: string;
}

export function InfoTooltip({ content, className }: Props) {
  return (
    <S.TooltipWrapper className={className}>
      <img src="/images/info.svg" alt="" />
      <S.TooltipBody className="tooltip-body">
        <T caption>{content}</T>
      </S.TooltipBody>
    </S.TooltipWrapper>
  );
}

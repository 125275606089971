import Button from "@marginly/ui/components/button";
import Thumbnail from "@marginly/ui/components/thumbnail";
import T from "@marginly/ui/components/typography";
import React from "react";

import { getTokenSymbol } from "@/app/util";
import PendingTxPartial, {
  PendingTxType,
} from "@/components/trade/partials/pending-tx";
import { ModalFarm, ModalLayout } from "@/external/components/modal.styled";
import { daysDisplay } from "@/util/days-display";
import { formatCoin } from "@/util/format-coin";

import Space from "../../common/space";
import { ModalTermBody } from "../styled";

interface Props {
  onClose?: () => void;
  days: number | null;
  onClosePosition: () => void;
  pending: boolean;
  symbol?: string;
  featureAmount: number;
  actualAmount: number;
  simulationPending: boolean;
}

export const TermWarningModal = ({
  onClose,
  days,
  onClosePosition,
  pending,
  symbol,
  featureAmount,
  actualAmount,
  simulationPending,
}: Props) => {
  const displaySymbol = getTokenSymbol(symbol);
  return (
    <>
      <ModalFarm className="farm">
        <ModalLayout onClick={onClose} />
        <Button onClick={onClose} className="close">
          <img src="/images/close.svg" alt="" />
        </Button>

        <ModalTermBody className="centred">
          <Thumbnail negative lg className="thumbnail">
            <img src="/images/clock-warning.svg" alt="" />
          </Thumbnail>
          <Space height={40} heightMobile={40} />

          <div>
            <T headerM>The term hasn&apos;t come yet</T>
            <Space height={8} heightMobile={4} />
            <T body>
              You farmed only {formatCoin(actualAmount)}&nbsp;
              {displaySymbol} so far. Wait {daysDisplay(days || 0)} to harvest{" "}
              {formatCoin(featureAmount)} {displaySymbol}.
            </T>

            <Space height={48} heightMobile={48} />

            <Button
              lg
              fullWidth
              className="btn--action"
              onClick={onClosePosition}
              pending={simulationPending}
            >
              Harvest Anyway
            </Button>
            <Space height={16} heightMobile={16} />
            <Button
              lg
              className="btn--action"
              secondary
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </ModalTermBody>
      </ModalFarm>
      {pending && <PendingTxPartial type={PendingTxType.Pending} />}
    </>
  );
};

import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  display: flex;
  padding: 4px 8px 4px 4px;
  align-items: center;
  gap: 2px;
  background: #c8ff26;
  .caption {
    color: var(--text-primary-on-brand);
  }
  img {
    width: 14px;
    height: 14px;
  }
`;

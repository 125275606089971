"use client";
import BigNumber from "bignumber.js";

export const getPercentsToLiquidation = (
  currentPrice: BigNumber | undefined,
  liquidationPrice: number | undefined | null,
) => {
  if (!currentPrice || !liquidationPrice) return undefined;
  if (!Number.isFinite(liquidationPrice)) return undefined;
  if (!currentPrice.isFinite() || currentPrice.eq(0)) return undefined;

  const diff = liquidationPrice - currentPrice.toNumber();

  return diff / currentPrice.toNumber();
};

import T from "@marginly/ui/components/typography";
import React from "react";

import { numberFormatter } from "@/util/formatters";

import * as S from "./styled";
interface Props {
  score?: number;
  short?: boolean;
  className?: string;
}

export function SparkScoreLabel({ score, short, className }: Props) {
  if (!score) {
    return null;
  }
  return (
    <S.Wrapper className={className}>
      <img src="/images/airdrop/airdrop-btn.svg" alt="" />
      <T caption>
        {numberFormatter(Math.ceil(score))}
        {!short && " Sparks"}
      </T>
    </S.Wrapper>
  );
}
